import styled from 'styled-components'
import Comba from 'src/styles/fonts/comba-bold-ultra-wide.otf'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

const BackgroundHero = 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-hero-jovem/image.webp'

export const Section = styled.section`
  min-height: 323px;
  background-color: #E5772A;
  
  @media ${device.tablet} {
    background: url(${BackgroundHero}) no-repeat center center;
    min-height: 333px;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background: url(${BackgroundHero}) no-repeat center center;
    min-height: 469px;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    background: url(${BackgroundHero}) no-repeat center center;
    min-height: 660px;
    background-size: cover;
  } 

  @media ${device.desktopXXXL} {
    background: url(${BackgroundHero}) no-repeat center center;
    min-height: calc(100vh - 73px);
    background-size: cover;
  }

  @font-face {
    font-family: 'Comba';
    src: url(${Comba}) format('opentype');
    font-display: swap;
  }

  h1 {
    font-family: 'Comba' !important;
    margin-top: 10px;
  }

  .container {
    @media ${device.tablet} {
      position: relative;
      top: -10px;
    }
  }

  .inter-you {
    margin-bottom: 20px;
  }
  
  .paragraph {
    margin-top: 18px;
    margin-bottom: 33px;

    @media ${device.tablet} {
      margin-bottom: 31px;
    }
    @media ${device.desktopXL} {
      margin-top: 25px;
      margin-bottom: 42px;
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    width: 156px;
    @media ${device.tablet} {
      width: 184px;
    }
    @media ${device.desktopLG} {
      width: 228px;
    }
    @media ${device.desktopXL} {
      width: 312px;
    }
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
  width: 100%;
  height: 40px;

  &:focus { 
    outline: none; 
  } 

  &:hover {
    color: ${grayscale[400]};
  }
  
  @media ${device.tablet} {
    width: 336px;
    margin-top: 10px;
  }

  @media ${device.desktopLG} {
    width: 456px;
    margin-top: 12px;
  }

  @media ${device.desktopXL} {
    width: 457px;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${white};
  border-radius: 8px;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`
