import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import Bg from '../../assets/images/bg-carousel.png'

export const Card = styled.div`
  @media ${device.tablet} {
    min-height: 184px
  }
  @media ${device.desktopLG} {
    min-height: 224px
  }
`

export const BgCarousel = styled.div`
  background: url(${Bg}) no-repeat center center;
  background-size: contain;
  width: 312px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

export const Description = styled.p`
  min-height: 40px;
`

export const Circle = styled.span`
  width: 44px;
  height: 44px;
  margin: 0 auto;
`

export const ContentText = styled.div`
  min-height: 170px;

  @media ${device.tablet} {
    min-height: 180px;
  }
`
