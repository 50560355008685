import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { WIDTH_MD } from 'src/styles/breakpoints'

import Hero from './sections/hero/_index'
import VoceNoControle from './sections/voce-no-controle/_index'
import Autonomia from './sections/autonomia/_index'
import GratuitaPersonalizadaSegura from './sections/gratuita-personalizada-segura/_index'
import PassoAPasso from './sections/passo-a-passo/_index'
import AbrimosAsPortas from './sections/abrimos-as-portas/_index'
import FAQ from './sections/faq/_index'

import pageContext from './pageContext.json'
import QrCode from './assets/images/inter-you-qr-code.jpg'

import { Wrapper } from './style'

const InterYou = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const domReady = useDomReady()
  const width = useWidth(300)

  const oneLink = 'https://intergo.app/e99b1aa7'

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-20 lh-25 fs-md-24 lh-md-30">Escaneie o QR Code, <a target="_blank" rel="noreferrer" href="https://intergo.app/e99b1aa7" class="text-orange--extra">acesse o app </a>e abra sua conta Inter You</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o nosso app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {hubModal}
        <Hero
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <VoceNoControle
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <Autonomia />
        <GratuitaPersonalizadaSegura
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <PassoAPasso />
        <AbrimosAsPortas
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLink}
        />
        <FAQ faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default InterYou
