import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import VoceNoControleJSON from '../../assets/data/voce-no-controle.json'

import { Section, Card, Button, Link } from './style'

type VoceNoControleData = {
  title: string;
  description: string;
  icon: string;
}

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  oneLink: string;
  setIsOpen: Function;
  isMobile: boolean;
}

const VoceNoControle = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Abra sua conta',
      section_name: 'Você no controle da sua vida financeira',
    })
  }
  return (
    <Section className='py-5 pt-md-3 pb-mb-2 bg-orange--super-light d-flex align-items-end align-items-lg-center'>
      <div className='container pb-lg-2 pt-lg-3 py-xl-5'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 mb-0 font-comba'>
              <span className='d-block d-md-inline'>Você no</span> <span className='d-block d-md-inline'>controle da sua</span> vida financeira
            </h2>
            {!isMobile &&
              <Button onClick={handleButtonClick} title='Abra sua conta' className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none button-modal'>
                Abra sua conta
              </Button>
            }
          </div>
          <div className='col-12 col-md-6 offset-xl-1 px-md-0'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 43 }}
              md={{ items: 1, partialVisibilityGutter: 86 }}
              lg={{ items: 1, partialVisibilityGutter: 100 }}
              xl={{ items: 2, partialVisibilityGutter: 52 }}
            >
              {
                VoceNoControleJSON.map((item: VoceNoControleData, index: number) => (
                  <div key={index} className='col-12 px-md-2'>
                    <Card className='rounded-3 bg-white px-4 px-md-3 px-lg-4 pt-4 mb-3'>
                      <OrangeIcon size='LG' color='#ff7a00' icon={item.icon} />
                      <h3 className='fs-16 lh-20 text-grayscale--500 mt-3 mb-2 mb-xl-2'>{item.title}</h3>
                      <p className='fs-16 lh-20 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Card>
                  </div>
                ))
              }
            </DefaultCarousel>
            {
              isMobile && (
                <Link
                  href={oneLink}
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none button-modal mt-4'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_2',
                      element_action: 'click button',
                      element_name: 'Abra sua conta',
                      section_name: 'Você no controle da sua vida financeira',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Abra sua conta
                </Link>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default VoceNoControle
