import styled from 'styled-components'
import Comba from '../../../../../../styles/fonts/comba-bold-ultra-wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Comba';
    src: url(${Comba}) format('opentype');
    font-display: swap;
  }

  h2 {
    font-family: 'Comba' !important;
  }
`
