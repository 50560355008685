import React from 'react'
import { Section } from './style'
import ImageWebp from 'src/components/ImageWebp'

const ChavePix = 'https://central-imagens.bancointer.com.br/images-without-small-versions/img1-dobra3/image.webp'
const CartaoDeDebito = 'https://central-imagens.bancointer.com.br/images-without-small-versions/img2-dobra3/image.webp'
const CartaoDeCredito = 'https://central-imagens.bancointer.com.br/images-without-small-versions/img3-dobra3/image.webp'

const Autonomia = () => {
  return (
    <Section className='py-5 d-flex align-items-end align-items-lg-center'>
      <div className='container py-lg-2 py-xl-5'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-20 fs-md-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 text-md-center mb-xl-3'>
              Autonomia pra ser quem você quiser
            </h2>
          </div>
          <div className='col-12 col-md-4 mb-3 text-center'>
            <ImageWebp
              arrayNumbers={[ 310, 215, 296, 325, 325 ]}
              arrayNumbersHeight={[ 192, 141, 195, 214, 214 ]}
              pathSrc={ChavePix}
              altDescription='Pessoa segurando copo do Inter e celular laranja'
            />
            <h3 className='fs-20 fs-lg-24 fs-xl-32 lh-25 lh-md-30 lh-xl-40 text-grayscale--500 fw-600 text-center mt-3 mt-md-4'>Pix</h3>
            <p className='fs-16 fs-xl-18 lh-20 text-grayscale--400 fw-400 text-center'>
              Cadastre suas chaves e faça pagamentos ilimitados a qualquer hora do dia.
            </p>
          </div>
          <div className='col-12 col-md-4 mb-3 text-center'>
            <ImageWebp
              arrayNumbers={[ 310, 215, 296, 325, 325 ]}
              arrayNumbersHeight={[ 192, 141, 195, 214, 214 ]}
              pathSrc={CartaoDeDebito}
              altDescription='Jovem sorrindo e simulando enquadramento de câmera com as mãos'
            />
            <h3 className='fs-20 fs-lg-24 fs-xl-32 lh-25 lh-md-30 lh-xl-40 text-grayscale--500 fw-600 text-center mt-3 mt-md-4'>
              Cartão de débito
            </h3>
            <p className='fs-16 fs-xl-18 lh-20 text-grayscale--400 fw-400 text-center'>
              É você quem gerencia o seu cartão! Faça compras online e em lojas físicas e pague com Inter You.
            </p>
          </div>
          <div className='col-12 col-md-4 text-center'>
            <ImageWebp
              arrayNumbers={[ 310, 215, 296, 325, 325 ]}
              arrayNumbersHeight={[ 192, 141, 195, 214, 214 ]}
              pathSrc={CartaoDeCredito}
              altDescription='Jovem sorrindo segurando cartão de crédito Inter'
            />
            <h3 className='fs-20 fs-lg-24 fs-xl-32 lh-25 lh-md-30 lh-xl-40 text-grayscale--500 fw-600 text-center mt-3 mt-md-4'>
              Cartão de crédito
            </h3>
            <p className='fs-16 fs-xl-18 lh-20 text-grayscale--400 fw-400 text-center'>
              Invista na Poupança Mais Limite e, enquanto o seu dinheiro rende, tenha o valor revertido em limite pra
              gastar com o que quiser.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Autonomia
