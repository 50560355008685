import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Card, Section, Button, Link } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  oneLink: string;
  setIsOpen: Function;
  isMobile: boolean;
}

const AbrimosAsPortas = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_6',
      element_action: 'click button',
      element_name: 'Abra sua conta',
      section_name: 'Abrimos as portas para um Super App',
    })
  }

  return (
    <Section className='py-5 bg-orange--medium-light d-flex align-items-center'>
      <div className='container py-lg-2 py-xl-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-4'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              <span className='d-xl-block'>Abrimos as</span> <span className='d-xl-block'>portas para um</span> Super App
            </h2>
            <p className='fs-16 fs-xl-18 lh-20 lh-xl-22 text-grayscale--500 mt-4'>
              <span className='d-xl-block'><span className='fw-700'>Inter You vai além</span> e te acompanha em</span> <span className='d-xl-block'>vários momentos. Oferecemos uma</span>
              <span className='d-xl-block'> grande gama de serviços no Super App</span> para facilitar todo o seu dia:
            </p>
            {!isMobile &&
              <Button onClick={handleButtonClick} title='Abra sua conta' className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none button-modal'>
                Abra sua conta
              </Button>
            }
          </div>
          <div className='col-12 col-md-6 col-lg-8'>
            <div className='row mt-2'>
              <div className='col-6 col-lg-3 mb-3 mb-md-4'>
                <Card className='bg-white rounded-3 d-flex flex-column justify-content-center py-4'>
                  <div className='d-flex justify-content-center mb-xl-3'><OrangeIcon size='MD' color='#ff7a00' icon='investments' /></div>
                  <h3 className='fs-14 fs-md-16 fs-xl-18 lh-21 grayscale--500 fw-400 text-center mt-4 mt-md-4'><span className='d-block'>Plataforma de</span> investimentos</h3>
                </Card>
              </div>
              <div className='col-6 col-lg-3 mb-3 mb-md-4'>
                <Card className='bg-white rounded-3 d-flex flex-column justify-content-center py-4'>
                  <div className='d-flex justify-content-center mb-xl-3'><OrangeIcon size='MD' color='#ff7a00' icon='gift' /></div>
                  <h3 className='fs-14 fs-md-16 fs-xl-18 lh-21 grayscale--500 fw-400 text-center mt-4 mt-md-4'>Gift Cards</h3>
                </Card>
              </div>
              <div className='col-6 col-lg-3 mb-3 mb-md-4'>
                <Card className='bg-white rounded-3 d-flex flex-column justify-content-center py-4'>
                  <div className='d-flex justify-content-center mb-xl-3'><OrangeIcon size='MD' color='#ff7a00' icon='shopping-bag' /></div>
                  <h3 className='fs-14 fs-md-16 fs-xl-18 lh-21 grayscale--500 fw-400 text-center mt-4 mt-md-4'><span className='d-block'>Shopping</span> completo</h3>
                </Card>
              </div>
              <div className='col-6 col-lg-3 mb-3 mb-md-4'>
                <Card className='bg-white rounded-3 d-flex flex-column justify-content-center py-4'>
                  <div className='d-flex justify-content-center mb-xl-3'><OrangeIcon size='MD' color='#ff7a00' icon='call-credits' /></div>
                  <h3 className='fs-14 fs-md-16 fs-xl-18 lh-21 grayscale--500 fw-400 text-center mt-4 mt-md-4'><span className='d-block'>Recarga de</span> celular</h3>
                </Card>
              </div>
              {
              isMobile && (
                <Link
                  href={oneLink}
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none button-modal'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_6',
                      element_action: 'click button',
                      element_name: 'Abra sua conta',
                      section_name: 'Abrimos as portas para um Super App',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Abra sua conta
                </Link>
              )
            }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AbrimosAsPortas
