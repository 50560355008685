import React from 'react'

import { Section, Button, Link } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  oneLink: string;
  setIsOpen: Function;
  isMobile: boolean;
}

const Hero = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Abra sua conta',
      section_name: 'Inter You. É hora de bancar suas escolhas',
    })
  }

  return (
    <Section className='py-5 py-md-3 d-flex align-items-md-center'>
      <div className='container d-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-7 col-xl-11'>
            <h1 className='font-comba fs-20 fs-md-24 fs-lg-32 lh-24 lh-md-28 lh-lg-38 text-white mt-md-3'>
              <span className='d-block font-inter inter-you'><span className='fs-32 fs-md-40 fs-lg-48 fw-700'>inter</span> <span className='fs-20 fs-md-24 fs-lg-32 fw-100'>YOU</span></span>
              <span className='d-xl-block'>É hora de bancar</span> suas escolhas
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-20 lh-16 lh-lg-20 lh-xl-21 fw-400 text-white paragraph'>
              Sua conta jovem gratuita com muito mais liberdade.
            </p>
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      element_action: 'click button',
                      element_name: 'Abra sua conta',
                      section_name: 'Inter You. É hora de bancar suas escolhas',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Abra sua conta
                </Link>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  title='Abra sua conta'
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
                >
                  Abra sua conta
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
