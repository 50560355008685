import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      logoInterYou: imageSharp(fluid: { originalName: { regex: "/logo-inter-you/" }}) {
        fluid(maxWidth: 312, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      contagratuitaPersonalizadaSeguraMobile: imageSharp(fluid: { originalName: { regex: "/conta-gratuita-segura-mobile/" }}) {
        fluid(maxWidth: 312, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
