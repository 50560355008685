import React from 'react'
import Img from 'gatsby-image'

import { WIDTH_MD } from 'src/utils/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import usePageQuery from './../../pageQuery'
import { Section, Button, Link } from './style'

const ImageGratuitaPersonalizadaSegura = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-4-games/image.webp'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  oneLink: string;
  setIsOpen: Function;
  isMobile: boolean;
}

const GratuitaPersonalizadaSegura = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: IModalDataLayerProps) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const title = <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 mb-3 mb-md-3'><span className='d-block d-xl-inline'><span className='d-xl-block'>Gratuita, personalizada</span> e</span> segura</h2>

  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      element_action: 'click button',
      element_name: 'Abrir minha própria conta',
      section_name: 'Gratuita, personalizada e segura',
    })
  }

  return (
    <Section className='py-5 bg-orange--super-light'>
      <div className='container py-lg-2 py-xl-5'>
        <div className='row'>
          {width < WIDTH_MD && title}
        </div>
        <div className='row align-items-center'>
          <div className='col-12 col-md-4 col-xl-3 offset-xl-1 mt-1 d-flex d-md-block justify-content-center'>
            { width < WIDTH_MD
            ? <Img fluid={data.contagratuitaPersonalizadaSeguraMobile.fluid} alt='Jovem sorrindo e segurando controle de videogame' />
            : <ImageWebp
                arrayNumbers={[ 312, 302, 302, 302, 302 ]}
                arrayNumbersHeight={[ 211, 476, 476, 476, 476 ]}
                pathSrc={ImageGratuitaPersonalizadaSegura}
                altDescription='Jovem sorrindo e segurando controle de videogame'
              />
            }
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-5 offset-md-2 offset-lg-1 offset-xl-2'>
            {width >= WIDTH_MD && title}
            <p className='fs-16 fs-xl-20 lh-19 lh-xl-25 fw-400 text-grayscale--500 mt-4 mt-lg-2'>
              <span className='d-block'>Um cartão com o <span className='text-orange--extra fw-600'>seu nome</span></span>
              <span className='d-block'>para cuidar do <span className='text-orange--extra fw-600'>seu dinheiro</span></span>
              <span className='d-block'>e garantir a <span className='text-orange--extra fw-600'>sua liberdade</span></span>
            </p>
            <p className='fs-16 fs-xl-20 lh-19 lh-xl-25 fw-400 text-grayscale--500'>
              Tudo isso com uma conta e um Super App seguros.
            </p>
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none button-modal'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      element_action: 'click button',
                      element_name: 'Abrir minha própria conta',
                      section_name: 'Gratuita, personalizada e segura',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Abrir minha própria conta
                </Link>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  title='Abrir minha própria conta'
                  className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none button-modal'
                >
                  Abrir minha própria conta
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GratuitaPersonalizadaSegura
