import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { WIDTH_MD } from 'src/utils/breakpoints'
import ImageWebp from 'src/components/ImageWebp'

import PassoAPassoJSON from '../../assets/data/passo-a-passo.json'

import { BgCarousel, Description, Circle, ContentText } from './style'

type PassoAPassoData = {
  title: string;
  description: string;
  imageMobile: string;
  image: string;
  altText: string;
}

const PassoAPasso = () => {
  const width = useWidth(300)

  return (
    <section className='py-5'>
      <div className='container py-lg-2 py-xl-5'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-16 fs-md-24 fs-lg-32 fs-xl-40 lh-20 lh-md-30 lh-lg-40 text-grayscale--500 text-md-center fw-600'>
              O passo a passo é simples
            </h2>
            <p className='fs-md-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-21 text-grayscale--400 d-none d-md-block text-md-center mt-md-3'>
              Aqui não tem complicação, você abre a conta Inter You em poucos minutos:
            </p>
          </div>
        </div>
        <div className='row'>
          {width < WIDTH_MD
            ? (
              <div className='col-12'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 4 }}
                  lg={{ items: 4 }}
                  xl={{ items: 4 }}
                >
                  {
                  PassoAPassoJSON.map((item: PassoAPassoData, index: number) => (
                    <div key={index}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex'>
                          <span className='fs-24 lh-20 text-grayscale--500 fw-600 mr-2'>{index + 1} - </span>
                          <p className='fs-24 lh-20 text-grayscale--500 fw-600' dangerouslySetInnerHTML={{ __html: item.title }} />
                        </div>
                      </div>
                      <Description className='fs-16 lh-20 text-grayscale--400' dangerouslySetInnerHTML={{ __html: item.description }} />
                      <BgCarousel className='mt-4'>
                        <ImageWebp
                          arrayNumbers={[ 190 ]}
                          arrayNumbersHeight={[ 383.33 ]}
                          pathSrc={item.image}
                          altDescription={item.altText}
                        />
                      </BgCarousel>
                    </div>
                  ))
                }
                </DefaultCarousel>
              </div>
            )
            : PassoAPassoJSON.map((item: PassoAPassoData, index: number) => (
              <div key={index} className='col-md-3 mt-md-3 mt-lg-4 d-md-flex flex-md-column align-items-md-center'>
                <ContentText>
                  <p className='fs-md-16 fs-lg-20 lh-md-20 lh-lg-25 text-grayscale--500 fw-600 text-md-center d-flex flex-column'>
                    <Circle className='fs-md-22 bg-orange--extra text-white rounded-50 d-flex align-items-center justify-content-center'>{index + 1}</Circle>
                    <span className='mt-2' dangerouslySetInnerHTML={{ __html: item.title }} />
                  </p>
                  <Description className='fs-md-14 fs-lg-16 lh-md-16 lh-lg-19 text-grayscale--400 text-md-center' dangerouslySetInnerHTML={{ __html: item.description }} />
                </ContentText>
                <ImageWebp
                  arrayNumbers={[ 312, 159, 209, 227, 227 ]}
                  arrayNumbersHeight={[ 211, 320.78, 421.66, 457.97, 457.97 ]}
                  pathSrc={item.image}
                  altDescription={item.altText}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default PassoAPasso
