import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import Comba from '../../../../../../styles/fonts/comba-bold-ultra-wide.otf'

export const Section = styled.section`
  @font-face {
    font-family: 'Comba';
    src: url(${Comba}) format('opentype');
    font-display: swap;
  }

  .font-comba {
    font-family: 'Comba' !important;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
    @media ${device.tablet} {
      left: -7px;
    }
    @media ${device.desktopLG} {
      left: -3px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media ${device.tablet} {
      right: -2px;
    }
    @media ${device.desktopLG} {
      right: 3px;
    }
    @media (min-width: 1600px) {
      right: 122px;
    }
  }
`

export const Card = styled.div`
  min-height: 207px;
  @media ${device.tablet} {
    min-height: 220px
  }
  @media ${device.desktopLG} {
    min-height: 236px;
  }
  @media ${device.desktopXL} {
    min-height: 224px
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${white};
  margin-top: 24px;
  &:focus { outline: none; } 

  @media ${device.tablet}{
    max-width: 335px;
    margin-top: 40px;
  }

  @media ${device.desktopLG}{
    max-width: 456px;
    margin-top: 32px;
  }

  @media ${device.desktopXL}{
    margin-top: 35px
  }

  &:hover {
    color: ${grayscale[400]};
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${white};
  border-radius: 8px;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus { outline: none; } 

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`
