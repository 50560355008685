import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  @media ${device.desktopLG} {
    min-height: 349px;
  }

  @media ${device.desktopXL} {
    min-height: 456px;
  }
`

export const Card = styled.div`
  min-height: 150px;

  @media ${device.tablet} {
    min-height: 146px
  }

  @media ${device.desktopLG} {
    min-height: 159px
  }

  @media ${device.desktopXL} {
    min-height: 194px
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${white};
  margin-top: 24px;
  &:focus { outline: none; } 

  @media ${device.tablet}{
    max-width: 297px;
  }

  @media ${device.desktopLG}{
    max-width: 296px;
  }

  @media ${device.desktopXL}{
    max-width: 360px;
  }

  &:hover {
    color: ${grayscale[400]};
  }
`

export const Link = styled.a`
  width: 97%;
  margin: 0 auto;
  height: 40px;
  background: ${white};
  border-radius: 8px;
  font-weight: bold;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus { outline: none; } 

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`
